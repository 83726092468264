<template>
  <div>
    <v-card rounded="lg" flat outlined class="pa-2">
      <v-stepper elevation="0" alt-labels v-model="detail_tab">
        <div id="scroll-target" class="overflow-y-auto" style="max-width: 100%">
          <v-stepper-header v-scroll:#scroll-target="onScroll">
            <div class="d-flex align-center justify-center full_w">
              <template v-for="(item, n) in field3">
                <v-stepper-step
                  color="#052633"
                  :complete="detail_tab > n"
                  :key="n"
                  :step="n + 1"
                >
                  <p class="text-center medium_txt ma-0">
                    {{ item.step }}
                  </p>
                </v-stepper-step>
                <v-divider
                  class="bg_color1"
                  :key="`divider${n}`"
                  v-if="n < field3.length - 1"
                />
              </template>
            </div>
          </v-stepper-header>
        </div>
      </v-stepper>

      <v-stepper
        :key="reload"
        elevation="0"
        v-model="detail_tab"
        v-if="!loading && field3.length > 0"
      >
        <v-stepper-items>
          <div v-for="(item, n) in field3" :key="n">
            <v-stepper-content :step="n + 1">
              <div v-if="item.step == 'Registrasi' && field3.length > 0">
                <div>
                  <h4>Deskripsi</h4>
                  <v-textarea
                    v-model="field3[n].deskripsi"
                    placeholder="Silakan Isi deskripsi disini"
                    color="#0D47A1"
                    dense
                    hide-details
                    outlined
                    rows="3"
                    auto-grow
                    class="rounded-lg"
                  ></v-textarea>
                </div>
                <div class="mt-3">
                  <v-row no-gutters>
                    <v-col cols="12" md="4" class="pr-5">
                      <h4>
                        <b>Tanggal Mulai</b>
                      </h4>
                      <v-menu
                        v-model="menuStartDate[n]"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                        max-width="290px"
                      >
                        <template v-slot:activator="{ on }">
                          <v-text-field
                            v-model="field3[n].start_date"
                            prepend-inner-icon="mdi-calendar-range"
                            readonly
                            placeholder="Silakan isi tanggal berlaku di sini"
                            hide-details
                            dense
                            v-on="on"
                            outlined
                            class="rounded-lg"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="field3[n].start_date"
                          @input="
                            closeDateMenu(field3[n].start_date, 'start_date', n)
                          "
                        ></v-date-picker>
                      </v-menu>
                    </v-col>
                    <v-col cols="12" md="4" class="pr-5">
                      <h4>
                        <b>Tanggal Akhir</b>
                      </h4>
                      <v-menu
                        v-model="menuEndDate[n]"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                        max-width="290px"
                      >
                        <template v-slot:activator="{ on }">
                          <v-text-field
                            v-model="field3[n].end_date"
                            prepend-inner-icon="mdi-calendar-range"
                            readonly
                            placeholder="Silakan isi tanggal berlaku di sini"
                            hide-details
                            dense
                            v-on="on"
                            outlined
                            class="rounded-lg"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="field3[n].end_date"
                          @input="
                            closeDateMenu(field3[n].end_date, 'end_date', n)
                          "
                        ></v-date-picker>
                      </v-menu>
                    </v-col>
                  </v-row>
                </div>
                <div class="mt-3">
                  <v-row no-gutters>
                    <v-col cols="12" md="4" class="pr-5">
                      <h4>
                        <b>Waktu Mulai</b>
                      </h4>
                      <v-menu
                        :ref="`menuStart${n}`"
                        v-model="menuStartTime[n]"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                        max-width="290px"
                      >
                        <template v-slot:activator="{ on }">
                          <v-text-field
                            v-model="field3[n].start_time"
                            prepend-inner-icon="mdi-calendar-range"
                            readonly
                            placeholder="Silakan isi jam selesai"
                            hide-details
                            dense
                            v-on="on"
                            outlined
                            class="rounded-lg"
                          ></v-text-field>
                        </template>
                        <v-time-picker
                          v-model="field3[n].start_time"
                          full-width
                          @click:minute="
                            $refs[`menuStart${n}`][0].save(field3[n].start_time)
                          "
                        ></v-time-picker>
                      </v-menu>
                    </v-col>
                    <v-col cols="12" md="4" class="pr-5">
                      <h4>
                        <b>Waktu Selesai</b>
                      </h4>
                      <v-menu
                        :ref="`menuEnd${n}`"
                        v-model="menuEndTime[n]"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                        max-width="290px"
                      >
                        <template v-slot:activator="{ on }">
                          <v-text-field
                            v-model="field3[n].end_time"
                            prepend-inner-icon="mdi-calendar-range"
                            readonly
                            placeholder="Silakan isi jam selesai"
                            hide-details
                            dense
                            v-on="on"
                            outlined
                            class="rounded-lg"
                          ></v-text-field>
                        </template>
                        <v-time-picker
                          v-model="field3[n].end_time"
                          full-width
                          @click:minute="
                            $refs[`menuEnd${n}`][0].save(field3[n].end_time)
                          "
                        ></v-time-picker>
                      </v-menu>
                    </v-col>
                  </v-row>
                </div>
                <div class="mt-3">
                  <h4>Lokasi/Link</h4>
                  <v-textarea
                    v-model="field3[n].lokasi"
                    placeholder="Silakan Isi lokasi / link disini"
                    color="#0D47A1"
                    dense
                    hide-details
                    outlined
                    auto-grow
                    rows="3"
                    class="rounded-lg"
                  ></v-textarea>
                </div>
                <div class="mt-3">
                  <h4>File</h4>
                  <v-text-field
                    v-if="field3[n].file_path"
                    readonly
                    v-model="field3[n].file"
                    label="Klik disini untuk upload file"
                    color="#0D47A1"
                    dense
                    hide-details
                    outlined
                    class="rounded-lg"
                    append-outer-icon="mdi-close"
                    append-icon="mdi-link"
                    @click:append="goTolink(field3[n].file_path)"
                    @click:append-outer="deleteFile(n)"
                  ></v-text-field>
                  <v-file-input
                    v-if="!field3[n].file_path"
                    v-model="field3[n].file"
                    label="Klik disini untuk upload file"
                    outlined
                    prepend-inner-icon="mdi-paperclip"
                    prepend-icon=""
                    dense
                    class="rounded-lg"
                    hide-details
                  ></v-file-input>
                </div>
                <div class="mt-3" v-if="linkform">
                  <h4>Link Form untuk klien</h4>
                  <v-text-field
                    readonly
                    :value="linkform"
                    placeholder="link form untuk klien"
                    color="#0D47A1"
                    dense
                    hide-details
                    outlined
                    class="rounded-lg"
                    ref="link1"
                    append-icon="mdi-content-copy"
                    @click:append="copyLink(linkform, 'link1')"
                  ></v-text-field>
                </div>
                <div
                  v-if="!linkform"
                  class="mt-3 d-flex align-start justify-start flex-column"
                >
                  <h4 class="not_bold">
                    Lanjutkan membuat Form Pendaftaran pada halaman Set Up
                    Pendaftaran dengan klik dibawah ini
                  </h4>

                  <v-btn
                    to="/admin/mo/setup-pendaftaran"
                    dark
                    class="bg_color1 text-capitalize"
                    depressed
                  >
                    Buat Form Pendaftaran
                  </v-btn>
                </div>
              </div>
              <div v-if="item.step == 'Invoice' && field3.length > 0">
                <div>
                  <h4>Deskripsi</h4>
                  <v-textarea
                    v-model="field3[n].deskripsi"
                    placeholder="Silakan Isi deskripsi disini"
                    color="#0D47A1"
                    dense
                    hide-details
                    outlined
                    class="rounded-lg"
                    rows="3"
                    auto-grow
                  ></v-textarea>
                </div>
                <div class="mt-3">
                  <v-row no-gutters>
                    <v-col cols="12" md="4" class="pr-5">
                      <h4>
                        <b>Tanggal Mulai</b>
                      </h4>
                      <v-menu
                        v-model="menuStartDate[n]"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                        max-width="290px"
                      >
                        <template v-slot:activator="{ on }">
                          <v-text-field
                            v-model="field3[n].start_date"
                            prepend-inner-icon="mdi-calendar-range"
                            readonly
                            placeholder="Silakan isi tanggal berlaku di sini"
                            hide-details
                            dense
                            v-on="on"
                            outlined
                            class="rounded-lg"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="field3[n].start_date"
                          @input="
                            closeDateMenu(field3[n].start_date, 'start_date', n)
                          "
                        ></v-date-picker>
                      </v-menu>
                    </v-col>
                    <v-col cols="12" md="4" class="pr-5">
                      <h4>
                        <b>Tanggal Akhir</b>
                      </h4>
                      <v-menu
                        v-model="menuEndDate[n]"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                        max-width="290px"
                      >
                        <template v-slot:activator="{ on }">
                          <v-text-field
                            v-model="field3[n].end_date"
                            prepend-inner-icon="mdi-calendar-range"
                            readonly
                            placeholder="Silakan isi tanggal berlaku di sini"
                            hide-details
                            dense
                            v-on="on"
                            outlined
                            class="rounded-lg"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="field3[n].end_date"
                          @input="
                            closeDateMenu(field3[n].end_date, 'end_date', n)
                          "
                        ></v-date-picker>
                      </v-menu>
                    </v-col>
                  </v-row>
                </div>
                <div class="mt-3">
                  <v-row no-gutters>
                    <v-col cols="12" md="4" class="pr-5">
                      <h4>
                        <b>Waktu Mulai</b>
                      </h4>
                      <v-menu
                        :ref="`menuStart${n}`"
                        v-model="menuStartTime[n]"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                        max-width="290px"
                      >
                        <template v-slot:activator="{ on }">
                          <v-text-field
                            v-model="field3[n].start_time"
                            prepend-inner-icon="mdi-calendar-range"
                            readonly
                            placeholder="Silakan isi jam selesai"
                            hide-details
                            dense
                            v-on="on"
                            outlined
                            class="rounded-lg"
                          ></v-text-field>
                        </template>
                        <v-time-picker
                          v-model="field3[n].start_time"
                          full-width
                          @click:minute="
                            $refs[`menuStart${n}`][0].save(field3[n].start_time)
                          "
                        ></v-time-picker>
                      </v-menu>
                    </v-col>
                    <v-col cols="12" md="4" class="pr-5">
                      <h4>
                        <b>Waktu Selesai</b>
                      </h4>
                      <v-menu
                        :ref="`menuEnd${n}`"
                        v-model="menuEndTime[n]"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                        max-width="290px"
                      >
                        <template v-slot:activator="{ on }">
                          <v-text-field
                            v-model="field3[n].end_time"
                            prepend-inner-icon="mdi-calendar-range"
                            readonly
                            placeholder="Silakan isi jam selesai"
                            hide-details
                            dense
                            v-on="on"
                            outlined
                            class="rounded-lg"
                          ></v-text-field>
                        </template>
                        <v-time-picker
                          v-model="field3[n].end_time"
                          full-width
                          @click:minute="
                            $refs[`menuEnd${n}`][0].save(field3[n].end_time)
                          "
                        ></v-time-picker>
                      </v-menu>
                    </v-col>
                  </v-row>
                </div>
                <div class="mt-3">
                  <h4>Lokasi/Link</h4>
                  <v-textarea
                    v-model="field3[n].lokasi"
                    placeholder="Silakan Isi lokasi/ link disini"
                    color="#0D47A1"
                    dense
                    hide-details
                    outlined
                    auto-grow
                    rows="3"
                    class="rounded-lg"
                  ></v-textarea>
                </div>
                <div class="mt-3">
                  <h4>File</h4>
                  <v-text-field
                    v-if="field3[n].file_path"
                    readonly
                    v-model="field3[n].file_path"
                    label="Klik disini untuk upload file"
                    color="#0D47A1"
                    dense
                    hide-details
                    outlined
                    class="rounded-lg"
                    append-outer-icon="mdi-close"
                    append-icon="mdi-link"
                    @click:append="goTolink(field3[n].file_path)"
                    @click:append-outer="deleteFile(n)"
                  ></v-text-field>
                  <v-file-input
                    v-if="!field3[n].file_path"
                    v-model="field3[n].file"
                    label="Klik disini untuk upload file"
                    outlined
                    prepend-inner-icon="mdi-paperclip"
                    prepend-icon=""
                    dense
                    hide-details
                  ></v-file-input>
                </div>
              </div>
              <div v-if="item.step == 'Tes Psikologi' && field3.length > 0">
                <div>
                  <h4>Deskripsi</h4>
                  <v-textarea
                    v-model="field3[n].deskripsi"
                    placeholder="Silakan Isi deskripsi disini"
                    color="#0D47A1"
                    dense
                    hide-details
                    outlined
                    rows="3"
                    auto-grow
                    class="rounded-lg"
                  ></v-textarea>
                </div>
                <div class="mt-3">
                  <v-row no-gutters>
                    <v-col cols="12" md="4" class="pr-5">
                      <h4>
                        <b>Tanggal Mulai</b>
                      </h4>
                      <v-menu
                        v-model="menuStartDate[n]"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                        max-width="290px"
                      >
                        <template v-slot:activator="{ on }">
                          <v-text-field
                            v-model="field3[n].start_date"
                            prepend-inner-icon="mdi-calendar-range"
                            readonly
                            placeholder="Silakan isi tanggal berlaku di sini"
                            hide-details
                            dense
                            v-on="on"
                            outlined
                            class="rounded-lg"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="field3[n].start_date"
                          @input="
                            closeDateMenu(field3[n].start_date, 'start_date', n)
                          "
                        ></v-date-picker>
                      </v-menu>
                    </v-col>
                    <v-col cols="12" md="4" class="pr-5">
                      <h4>
                        <b>Tanggal Akhir</b>
                      </h4>
                      <v-menu
                        v-model="menuEndDate[n]"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                        max-width="290px"
                      >
                        <template v-slot:activator="{ on }">
                          <v-text-field
                            v-model="field3[n].end_date"
                            prepend-inner-icon="mdi-calendar-range"
                            readonly
                            placeholder="Silakan isi tanggal berlaku di sini"
                            hide-details
                            dense
                            v-on="on"
                            outlined
                            class="rounded-lg"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="field3[n].end_date"
                          @input="
                            closeDateMenu(field3[n].end_date, 'end_date', n)
                          "
                        ></v-date-picker>
                      </v-menu>
                    </v-col>
                  </v-row>
                </div>
                <div class="mt-3">
                  <v-row no-gutters>
                    <v-col cols="12" md="4" class="pr-5">
                      <h4>
                        <b>Waktu Mulai</b>
                      </h4>
                      <v-menu
                        :ref="`menuStart${n}`"
                        v-model="menuStartTime[n]"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                        max-width="290px"
                      >
                        <template v-slot:activator="{ on }">
                          <v-text-field
                            v-model="field3[n].start_time"
                            prepend-inner-icon="mdi-calendar-range"
                            readonly
                            placeholder="Silakan isi jam selesai"
                            hide-details
                            dense
                            v-on="on"
                            outlined
                            class="rounded-lg"
                          ></v-text-field>
                        </template>
                        <v-time-picker
                          v-model="field3[n].start_time"
                          full-width
                          @click:minute="
                            $refs[`menuStart${n}`][0].save(field3[n].start_time)
                          "
                        ></v-time-picker>
                      </v-menu>
                    </v-col>
                    <v-col cols="12" md="4" class="pr-5">
                      <h4>
                        <b>Waktu Selesai</b>
                      </h4>
                      <v-menu
                        :ref="`menuEnd${n}`"
                        v-model="menuEndTime[n]"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                        max-width="290px"
                      >
                        <template v-slot:activator="{ on }">
                          <v-text-field
                            v-model="field3[n].end_time"
                            prepend-inner-icon="mdi-calendar-range"
                            readonly
                            placeholder="Silakan isi jam selesai"
                            hide-details
                            dense
                            v-on="on"
                            outlined
                            class="rounded-lg"
                          ></v-text-field>
                        </template>
                        <v-time-picker
                          v-model="field3[n].end_time"
                          full-width
                          @click:minute="
                            $refs[`menuEnd${n}`][0].save(field3[n].end_time)
                          "
                        ></v-time-picker>
                      </v-menu>
                    </v-col>
                  </v-row>
                </div>
                <div class="mt-3">
                  <h4>Lokasi/Link</h4>
                  <v-textarea
                    v-model="field3[n].lokasi"
                    placeholder="Silakan Isi lokasi/ link disini"
                    color="#0D47A1"
                    dense
                    hide-details
                    outlined
                    rows="3"
                    auto-grow
                    class="rounded-lg"
                  ></v-textarea>
                </div>
                <div class="mt-3">
                  <h4>File</h4>
                  <v-text-field
                    v-if="field3[n].file_path"
                    readonly
                    v-model="field3[n].file_path"
                    label="Klik disini untuk upload file"
                    color="#0D47A1"
                    dense
                    hide-details
                    outlined
                    class="rounded-lg"
                    append-outer-icon="mdi-close"
                    append-icon="mdi-link"
                    @click:append="goTolink(field3[n].file_path)"
                    @click:append-outer="deleteFile(n)"
                  ></v-text-field>
                  <v-file-input
                    v-if="!field3[n].file_path"
                    v-model="field3[n].file"
                    label="Klik disini untuk upload file"
                    outlined
                    prepend-inner-icon="mdi-paperclip"
                    prepend-icon=""
                    dense
                    hide-details
                  ></v-file-input>
                </div>
                <div class="mt-3 d-flex align-start justify-start flex-column">
                  <h4 class="not_bold">
                    Lanjutkan membuat Tes Psikologi dengan klik dibawah ini
                  </h4>

                  <v-btn
                    @click="goTolink('https://dev-dashboard.anargya.id/')"
                    target="blank"
                    dark
                    class="bg_color1 text-capitalize"
                    depressed
                  >
                    Buat Tes Psikologi
                  </v-btn>
                </div>
              </div>
              <div v-if="item.step == 'Interview' && field3.length > 0">
                <div>
                  <h4>Deskripsi</h4>
                  <v-textarea
                    v-model="field3[n].deskripsi"
                    placeholder="Silakan Isi deskripsi disini"
                    color="#0D47A1"
                    dense
                    hide-details
                    outlined
                    rows="3"
                    auto-grow
                    class="rounded-lg"
                  ></v-textarea>
                </div>
                <div class="mt-3">
                  <v-row no-gutters>
                    <v-col cols="12" md="4" class="pr-5">
                      <h4>
                        <b>Tanggal Mulai</b>
                      </h4>
                      <v-menu
                        v-model="menuStartDate[n]"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                        max-width="290px"
                      >
                        <template v-slot:activator="{ on }">
                          <v-text-field
                            v-model="field3[n].start_date"
                            prepend-inner-icon="mdi-calendar-range"
                            readonly
                            placeholder="Silakan isi tanggal berlaku di sini"
                            hide-details
                            dense
                            v-on="on"
                            outlined
                            class="rounded-lg"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="field3[n].start_date"
                          @input="
                            closeDateMenu(field3[n].start_date, 'start_date', n)
                          "
                        ></v-date-picker>
                      </v-menu>
                    </v-col>
                    <v-col cols="12" md="4" class="pr-5">
                      <h4>
                        <b>Tanggal Akhir</b>
                      </h4>
                      <v-menu
                        v-model="menuEndDate[n]"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                        max-width="290px"
                      >
                        <template v-slot:activator="{ on }">
                          <v-text-field
                            v-model="field3[n].end_date"
                            prepend-inner-icon="mdi-calendar-range"
                            readonly
                            placeholder="Silakan isi tanggal berlaku di sini"
                            hide-details
                            dense
                            v-on="on"
                            outlined
                            class="rounded-lg"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="field3[n].end_date"
                          @input="
                            closeDateMenu(field3[n].end_date, 'end_date', n)
                          "
                        ></v-date-picker>
                      </v-menu>
                    </v-col>
                  </v-row>
                </div>
                <div class="mt-3">
                  <v-row no-gutters>
                    <v-col cols="12" md="4" class="pr-5">
                      <h4>
                        <b>Waktu Mulai</b>
                      </h4>
                      <v-menu
                        :ref="`menuStart${n}`"
                        v-model="menuStartTime[n]"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                        max-width="290px"
                      >
                        <template v-slot:activator="{ on }">
                          <v-text-field
                            v-model="field3[n].start_time"
                            prepend-inner-icon="mdi-calendar-range"
                            readonly
                            placeholder="Silakan isi jam selesai"
                            hide-details
                            dense
                            v-on="on"
                            outlined
                            class="rounded-lg"
                          ></v-text-field>
                        </template>
                        <v-time-picker
                          v-model="field3[n].start_time"
                          full-width
                          @click:minute="
                            $refs[`menuStart${n}`][0].save(field3[n].start_time)
                          "
                        ></v-time-picker>
                      </v-menu>
                    </v-col>
                    <v-col cols="12" md="4" class="pr-5">
                      <h4>
                        <b>Waktu Selesai</b>
                      </h4>
                      <v-menu
                        :ref="`menuEnd${n}`"
                        v-model="menuEndTime[n]"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                        max-width="290px"
                      >
                        <template v-slot:activator="{ on }">
                          <v-text-field
                            v-model="field3[n].end_time"
                            prepend-inner-icon="mdi-calendar-range"
                            readonly
                            placeholder="Silakan isi jam selesai"
                            hide-details
                            dense
                            v-on="on"
                            outlined
                            class="rounded-lg"
                          ></v-text-field>
                        </template>
                        <v-time-picker
                          v-model="field3[n].end_time"
                          full-width
                          @click:minute="
                            $refs[`menuEnd${n}`][0].save(field3[n].end_time)
                          "
                        ></v-time-picker>
                      </v-menu>
                    </v-col>
                  </v-row>
                </div>
                <div class="mt-3">
                  <h4>Metode</h4>
                  <v-radio-group
                    v-model="field3[n].metode"
                    hide-details
                    class="ma-0"
                    dense
                    row
                  >
                    <v-radio
                      label="Online"
                      color="#006341"
                      value="Online"
                    ></v-radio>
                    <v-radio
                      label="Offline"
                      color="#006341"
                      value="Offline"
                    ></v-radio>
                  </v-radio-group>
                </div>
                <div class="mt-3">
                  <h4>Lokasi/Link</h4>
                  <v-textarea
                    v-model="field3[n].lokasi"
                    placeholder="Silakan Isi lokasi/ link disini"
                    color="#0D47A1"
                    dense
                    hide-details
                    outlined
                    rows="3"
                    auto-grow
                    class="rounded-lg"
                  ></v-textarea>
                </div>
                <div class="mt-3">
                  <h4>File</h4>
                  <v-text-field
                    v-if="field3[n].file_path"
                    readonly
                    v-model="field3[n].file_path"
                    label="Klik disini untuk upload file"
                    color="#0D47A1"
                    dense
                    hide-details
                    outlined
                    class="rounded-lg"
                    append-outer-icon="mdi-close"
                    append-icon="mdi-link"
                    @click:append="goTolink(field3[n].file_path)"
                    @click:append-outer="deleteFile(n)"
                  ></v-text-field>
                  <v-file-input
                    v-if="!field3[n].file_path"
                    v-model="field3[n].file"
                    label="Klik disini untuk upload file"
                    outlined
                    prepend-inner-icon="mdi-paperclip"
                    prepend-icon=""
                    dense
                    hide-details
                  ></v-file-input>
                </div>
              </div>
              <div
                v-if="
                  item.step != 'Registrasi' &&
                  item.step != 'Invoice' &&
                  item.step != 'Tes Psikologi' &&
                  item.step != 'Interview' &&
                  field3.length > 0
                "
              >
                <div>
                  <h4>Deskripsi</h4>
                  <v-textarea
                    v-model="field3[n].deskripsi"
                    placeholder="Silakan Isi deskripsi disini"
                    color="#0D47A1"
                    dense
                    hide-details
                    outlined
                    rows="3"
                    auto-grow
                    class="rounded-lg"
                  ></v-textarea>
                </div>
                <div class="mt-3">
                  <v-row no-gutters>
                    <v-col cols="12" md="4" class="pr-5">
                      <h4>
                        <b>Tanggal Mulai</b>
                      </h4>
                      <v-menu
                        v-model="menuStartDate[n]"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                        max-width="290px"
                      >
                        <template v-slot:activator="{ on }">
                          <v-text-field
                            v-model="field3[n].start_date"
                            prepend-inner-icon="mdi-calendar-range"
                            readonly
                            placeholder="Silakan isi tanggal berlaku di sini"
                            hide-details
                            dense
                            v-on="on"
                            outlined
                            class="rounded-lg"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="field3[n].start_date"
                          @input="
                            closeDateMenu(field3[n].start_date, 'start_date', n)
                          "
                        ></v-date-picker>
                      </v-menu>
                    </v-col>
                    <v-col cols="12" md="4" class="pr-5">
                      <h4>
                        <b>Tanggal Akhir</b>
                      </h4>
                      <v-menu
                        v-model="menuEndDate[n]"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                        max-width="290px"
                      >
                        <template v-slot:activator="{ on }">
                          <v-text-field
                            v-model="field3[n].end_date"
                            prepend-inner-icon="mdi-calendar-range"
                            readonly
                            placeholder="Silakan isi tanggal berlaku di sini"
                            hide-details
                            dense
                            v-on="on"
                            outlined
                            class="rounded-lg"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="field3[n].end_date"
                          @input="
                            closeDateMenu(field3[n].end_date, 'end_date', n)
                          "
                        ></v-date-picker>
                      </v-menu>
                    </v-col>
                  </v-row>
                </div>
                <div class="mt-3">
                  <v-row no-gutters>
                    <v-col cols="12" md="4" class="pr-5">
                      <h4>
                        <b>Waktu Mulai</b>
                      </h4>
                      <v-menu
                        :ref="`menuStart${n}`"
                        v-model="menuStartTime[n]"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                        max-width="290px"
                      >
                        <template v-slot:activator="{ on }">
                          <v-text-field
                            v-model="field3[n].start_time"
                            prepend-inner-icon="mdi-calendar-range"
                            readonly
                            placeholder="Silakan isi jam selesai"
                            hide-details
                            dense
                            v-on="on"
                            outlined
                            class="rounded-lg"
                          ></v-text-field>
                        </template>
                        <v-time-picker
                          v-model="field3[n].start_time"
                          full-width
                          @click:minute="
                            $refs[`menuStart${n}`][0].save(field3[n].start_time)
                          "
                        ></v-time-picker>
                      </v-menu>
                    </v-col>
                    <v-col cols="12" md="4" class="pr-5">
                      <h4>
                        <b>Waktu Selesai</b>
                      </h4>
                      <v-menu
                        :ref="`menuEnd${n}`"
                        v-model="menuEndTime[n]"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                        max-width="290px"
                      >
                        <template v-slot:activator="{ on }">
                          <v-text-field
                            v-model="field3[n].end_time"
                            prepend-inner-icon="mdi-calendar-range"
                            readonly
                            placeholder="Silakan isi jam selesai"
                            hide-details
                            dense
                            v-on="on"
                            outlined
                            class="rounded-lg"
                          ></v-text-field>
                        </template>
                        <v-time-picker
                          v-model="field3[n].end_time"
                          full-width
                          @click:minute="
                            $refs[`menuEnd${n}`][0].save(field3[n].end_time)
                          "
                        ></v-time-picker>
                      </v-menu>
                    </v-col>
                  </v-row>
                </div>
                <div class="mt-3">
                  <h4>Lokasi/Link</h4>
                  <v-textarea
                    v-model="field3[n].lokasi"
                    placeholder="Silakan Isi lokasi/ link disini"
                    color="#0D47A1"
                    dense
                    hide-details
                    outlined
                    rows="3"
                    auto-grow
                    class="rounded-lg"
                  ></v-textarea>
                </div>
                <div class="mt-3">
                  <h4>File</h4>
                  <v-text-field
                    v-if="field3[n].file_path"
                    readonly
                    v-model="field3[n].file_path"
                    label="Klik disini untuk upload file"
                    color="#0D47A1"
                    dense
                    hide-details
                    outlined
                    class="rounded"
                    append-icon="mdi-link"
                    append-outer-icon="mdi-close"
                    @click:append="goTolink(field3[n].file_path)"
                    @click:append-outer="deleteFile(n)"
                  ></v-text-field>
                  <v-file-input
                    v-if="!field3[n].file_path"
                    v-model="field3[n].file"
                    label="Klik disini untuk upload file"
                    outlined
                    hide-details
                    prepend-inner-icon="mdi-paperclip"
                    prepend-icon=""
                    dense
                  ></v-file-input>
                </div>
              </div>
            </v-stepper-content>
          </div>
        </v-stepper-items>
      </v-stepper>

      <div class="mt-6 d-flex align-center justify-end">
        <v-menu transition="slide-y-transition" bottom offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              class="text-capitalize mr-2"
              min-width="150px"
              depressed
              outlined
              color="red"
              v-bind="attrs"
              v-on="on"
            >
              <h4 style="color: black">Reset</h4>
            </v-btn>
          </template>
          <v-list>
            <v-list-item>
              <v-btn
                block
                class="text-capitalize"
                depressed
                @click="resetType('all')"
              >
                <h4 style="color: black">Reset All</h4>
              </v-btn>
            </v-list-item>
            <v-list-item>
              <v-btn
                block
                class="text-capitalize"
                depressed
                @click="resetType('page')"
              >
                <h4 style="color: black">Reset</h4>
              </v-btn>
            </v-list-item>
          </v-list>
        </v-menu>

        <v-btn
          :disabled="detail_tab <= 1 ? true : false"
          width="15%"
          class="text-capitalize mr-2"
          depressed
          color="#BD9028"
          @click="prevStep"
        >
          <h4 class="txt_white">Kembali</h4>
        </v-btn>
        <div v-if="field3.length > 0">
          <v-btn
            :disabled="
              field3[detail_tab - 1].end_date &&
              field3[detail_tab - 1].start_date
                ? false
                : true
            "
            class="text-capitalize"
            depressed
            color="#052633"
            @click="nextStep"
          >
            <h4 v-if="!loadingButton" class="txt_white">Lanjut</h4>
            <h4 v-if="loadingButton" class="txt_white">Loading...</h4>
          </v-btn>
        </div>
      </div>
    </v-card>
    <div class="pa-2">
      <dialog-small
        :d_small="d_small"
        @refetch="type_reset == 'all' ? $emit('reset') : resetFunction()"
        @close="d_small = false"
        :inventory="inventory"
        :type="d_type"
        :title="d_title"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "detailTimeline",
  props: ["timeline", "field1", "field2", "linkform"],
  data() {
    return {
      type_reset: "all",
      d_small: false,
      d_type: "",
      d_title: "",
      inventory: "",
      line: "",
      filed: null,
      filed2: null,
      loading: true,
      loadingButton: false,
      detail_tab: 1,
      field3: [],
      menuStartDate: [],
      menuEndDate: [],
      menuStartTime: [],
      menuEndTime: [],
      reload: 1,
    };
  },
  mounted() {},
  methods: {
    async setField3() {
      this.loading = true;
      this.field3 = this.timeline;
      this.field3.forEach((item, index) => {
        this.menuStartDate.push(false);
        this.menuEndDate.push(false);
        this.menuStartTime.push(false);
        this.menuEndTime.push(false);
        this.reload = this.reload + 1;
      });
    },
    onScroll(e) {
      this.offsetTop = e.target.scrollTop;
    },
    setVariabel() {
      this.loading = false;
    },
    deleteFile(idx) {
      this.field3[idx].file = null;
      this.field3[idx].file_path = "";
    },
    nextStep() {
      if (this.detail_tab >= this.field3.length) {
        this.saveData("next", "");
      } else {
        this.detail_tab = this.detail_tab + 1;
      }
    },
    async saveData(type, tab) {
      this.loadingButton = true;
      let dataEvent = [];
      for (let i = 0; i < this.field3.length; i++) {
        dataEvent.push({ [`file${i}`]: this.field3[i].file });
      }
      dataEvent.push({
        step: 3,
      });
      dataEvent.push({
        customer_id: this.field1.id_klien,
      });
      dataEvent.push({
        tujuan_event: this.field1.tujuan_event,
      });
      dataEvent.push({
        tipe: this.field1.tipe_klien,
      });
      dataEvent.push({
        layanan_id: this.field2.jenis_layanan,
      });
      dataEvent.push({
        timelines: JSON.stringify(this.field3),
      });

      let path = `sysadmin/event/wizard/save`;
      let data = {
        body: dataEvent,
        path: path,
      };
      await this.$store
        .dispatch("fileData/postApi", data)
        .then((data) => {
          this.loadingButton = false;
          if (data.code == 200) {
            if (type == "next") {
              this.$emit("nextStep");
              this.detail_tab = 1;
            }
          } else if (data.code == 201) {
            if (data.data) {
              var key = Object.keys(data.data.errors);
              var values = Object.values(data.data.errors);
              for (let i = 0; i < key.length; i++) {
                for (let j = 0; j < values[i].length; j++) {
                  this.$toast.error(values[i][j]);
                }
              }
            } else {
              this.$store.dispatch("notify", {
                msg: data.message,
                type: "error",
              });
            }
          }
        })
        .catch((e) => {
          this.loadingButton = false;
          var key = Object.keys(e.data.errors);
          var values = Object.values(e.data.errors);
          if (key.length == 0) {
            this.$store.dispatch("notify", {
              msg: e.message,
              type: "error",
            });
          }
          for (let i = 0; i < key.length; i++) {
            for (let j = 0; j < values[i].length; j++) {
              this.$toast.error(values[i][j]);
            }
          }
          this.loading = false;
        });
    },
    prevStep() {
      this.detail_tab = this.detail_tab - 1;
    },
    resetType(item) {
      this.type_reset = item;
      this.inventory = {
        type: item,
      };
      this.d_type = "d_reset";
      this.d_title = "Reset Data";
      this.d_small = true;
      this.line = true;
    },
    async resetFunction() {
      let data = {
        id: this.field3[this.detail_tab - 1].id,
        step_id: this.field3[this.detail_tab - 1].step_id,
        step: this.field3[this.detail_tab - 1].step,
        deskripsi: "",
        start_date: "",
        end_date: "",
        metode: "",
        lokasi: "",
        file: null,
        file_path: "",
        order: this.field3[this.detail_tab - 1].order,
      };
      this.field3[this.detail_tab - 1] = data;
      this.reload = this.reload + 1;
      // this.$emit("reset");
    },
    resetAll() {
      this.$emit("reset");
    },
    closeDateMenu(item, type, idx) {
      if (type == "start_date") {
        this.menuStartDate[idx] = false;
        this.field3[idx].start_date == ""
          ? this.$date(item).format("dddd, MMMM Do YYYY")
          : "";
      } else {
        this.menuEndDate[idx] = false;
        this.field3[idx].end_date == ""
          ? this.$date(item).format("dddd, MMMM Do YYYY")
          : "";
      }
    },
    goTolink(link) {
      window.open(link);
    },
    copyLink(link, ref) {
      this.$copyText(link)
        .then((data) => {
          this.$store.dispatch("notify", {
            msg: "Copied",
            type: "success",
          });
        })
        .catch((e) => {
          this.$store.dispatch("notify", {
            msg: "Can not copy",
            type: "error",
          });
        });
    },
    getField3() {
      return this.field3;
    },
    setNullField3() {
      this.field3 = [];
    },
    setDetail() {
      this.detail_tab = 1;
    },
  },
};
</script>

<style></style>
