<template>
  <div>
    <div class="pa-4">
      <navbar-profile :items="items"></navbar-profile>
    </div>
    <div class="px-5">
      <bullet :canBack="true" colorIcon="#052633" title="Tambah Event" />
      <div class="mt-3">
        <v-stepper flat elevation="0" v-model="tab">
          <div class="d-flex align-center justify-center full_w">
            <v-stepper-header
              non-linear
              class="rounded-pill pa-1"
              style="height: max-content; background: #cfe1e1"
            >
              <v-stepper-step
                :editable="tab == 2 ? true : false"
                class="pa-4 rounded-pill text-capitalize"
                :step="1"
                :style="
                  tab == 1
                    ? 'height: 10px; background: white;'
                    : 'height: 10px; background: transparent; '
                "
                color="#052633"
              >
                <div>Info Layanan</div>
              </v-stepper-step>
              <v-stepper-step
                :editable="tab == 3 ? true : false"
                class="pa-4 rounded-pill text-capitalize"
                :step="2"
                :style="
                  tab == 2
                    ? 'height: 10px; background: white;'
                    : 'height: 10px; background: transparent; '
                "
                color="#052633"
              >
                <div>Timeline</div>
              </v-stepper-step>
              <v-stepper-step
                :editable="tab == 4 ? true : false"
                class="pa-4 rounded-pill text-capitalize"
                :step="3"
                :style="
                  tab == 3
                    ? 'height: 10px; background: white;'
                    : 'height: 10px; background: transparent; '
                "
                color="#052633"
                @click="prevStep(3)"
              >
                <div>Detail Timeline</div>
              </v-stepper-step>
              <v-stepper-step
                class="pa-4 rounded-pill text-capitalize"
                :step="4"
                :style="
                  tab == 4
                    ? 'height: 10px; background: white;'
                    : 'height: 10px; background: transparent; '
                "
                color="#052633"
              >
                <div>Preview</div>
              </v-stepper-step>
            </v-stepper-header>
          </div>
          <v-stepper-items>
            <v-stepper-content :step="1">
              <div class="d-flex align-center justify-center">
                <v-card
                  rounded="lg"
                  flat
                  outlined
                  class="pa-6 _temp_list_medium"
                >
                  <div v-if="!loading && dataClient">
                    <div>
                      <h4 class="not_bold">Nama Klien</h4>
                      <v-select
                        placeholder="Silakan isi nama di sini"
                        outlined
                        hide-details
                        :items="dataClient"
                        item-text="nama_lengkap"
                        item-value="id"
                        v-model="field1.id_klien"
                        dense
                        @change="checkNamaClient(field1.id_klien)"
                      ></v-select>
                    </div>
                    <div class="mt-3">
                      <h4 class="not_bold">Tujuan Event</h4>
                      <v-text-field
                        v-model="field1.tujuan_event"
                        placeholder="Silakan isi tujuan event di sini"
                        color="#0D47A1"
                        dense
                        hide-details
                        outlined
                        class="rounded"
                      ></v-text-field>
                    </div>
                    <div class="mt-3">
                      <h4 class="not_bold">Tipe Klien</h4>
                      <v-radio-group
                        v-model="field1.tipe_klien"
                        hide-details
                        class="ma-0"
                        dense
                        row
                      >
                        <v-radio
                          label="Individu"
                          color="#052633"
                          value="individu"
                        ></v-radio>
                        <v-radio
                          label="Project"
                          color="#052633"
                          value="project"
                        ></v-radio>
                      </v-radio-group>
                    </div>
                    <div class="mt-6 d-flex align-center justify-end">
                      <v-menu transition="slide-y-transition" bottom offset-y>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            class="text-capitalize mr-2"
                            min-width="150px"
                            depressed
                            outlined
                            color="red"
                            v-bind="attrs"
                            v-on="on"
                            :disabled="!loadingButton && !loading ? false: true"
                          >
                            <h4 style="color: black">Reset</h4>
                          </v-btn>
                        </template>
                        <v-list>
                          <v-list-item>
                            <v-btn
                              block
                              class="text-capitalize"
                              depressed
                              @click="resetType('all')"
                            >
                              <h4 style="color: black">Reset All</h4>
                            </v-btn>
                          </v-list-item>
                          <v-list-item>
                            <v-btn
                              block
                              class="text-capitalize"
                              depressed
                              @click="resetType('page')"
                            >
                              <h4 style="color: black">Reset</h4>
                            </v-btn>
                          </v-list-item>
                        </v-list>
                      </v-menu>
                      <v-btn
                        :disabled="
                          !loadingButton && !loading ?
                            tab == 1
                              ? field1.id_klien &&
                                field1.tujuan_event &&
                                field1.tipe_klien
                                ? false
                                : true
                              : false
                            : false
                        "
                        width="15%"
                        class="text-capitalize"
                        depressed
                        color="#052633"
                        @click="nextStep"
                      >
                        <h4 v-if="!loadingButton" class="txt_white">Lanjut</h4>
                        <h4 v-if="loadingButton" class="txt_white">
                          Loading...
                        </h4>
                      </v-btn>
                    </div>
                  </div>
                  <div v-if="loading">
                    <v-skeleton-loader
                      v-for="(item, n) in 3"
                      :key="n"
                      type="article"
                    ></v-skeleton-loader>
                  </div>
                </v-card>
              </div>
            </v-stepper-content>
            <v-stepper-content :step="2">
              <v-card rounded="lg" flat outlined class="pa-6 full_w">
                <div v-if="!loading && dataLayanan">
                  <div>
                    <h4 class="not_bold">Jenis Layanan</h4>
                    <v-select
                      placeholder="Pilih jenis layanan"
                      outlined
                      hide-details
                      :items="dataLayanan"
                      item-text="layanan"
                      item-value="id"
                      v-model="field2.jenis_layanan"
                      dense
                      @change="
                        fetchDataSubTimeline(
                          false,
                          field2.jenis_layanan,
                          false,
                          'clear',
                          'timeline'
                        )
                      "
                    ></v-select>
                  </div>
                  <div class="mt-3">
                    <v-row
                      no-gutters
                      class="rounded-lg"
                      style="
                        border-left: 1px solid #e9ecf1;
                        border-right: 1px solid #e9ecf1;
                        border-bottom: 1px solid #e9ecf1;
                      "
                    >
                      <v-col cols="12" md="3" class="mt-2">
                        <v-card
                          flat
                          rounded="0"
                          class="bg_color1 d-flex justify-center rounded-tl-lg"
                          dark
                        >
                          <v-card-title>Pilih Sub Timeline</v-card-title>
                        </v-card>
                        <div v-for="(item, n) in subTimeline" :key="n">
                          <v-card
                            flat
                            rounded="0"
                            class="mb-1 pa-2 d-flex align-center justify-center flex-column"
                            color="#E9ECF1"
                          >
                            <div style="width: 100%">
                              <div class="d-flex px-2 align-center">
                                <h4 style="flex-grow: 1">{{ item.step }}</h4>
                                <v-btn
                                  @click="addSubTimeline(n)"
                                  class="text-capitalize bg_color1"
                                  dark
                                >
                                  Pilih
                                </v-btn>
                              </div>
                            </div>
                          </v-card>
                        </div>
                        <v-card
                          flat
                          rounded="0"
                          class="pa-2 d-flex align-center justify-center flex-column rounded-bl-lg"
                          color="#E9ECF1"
                        >
                          <div style="width: 100%">
                            <div class="d-flex px-2 align-center">
                              <v-text-field
                                v-model="custom_subtimeline"
                                placeholder="Custom"
                                hide-details
                                dense
                                class="mr-2"
                                flat
                                solo
                                style="background: white"
                              ></v-text-field>
                              <v-btn
                                :disabled="field2.jenis_layanan ? false : true"
                                @click="addSubTimeline(-2)"
                                class="text-capitalize bg_color1"
                              >
                                <h4 style="color: white">Pilih</h4>
                              </v-btn>
                            </div>
                          </div>
                        </v-card>
                      </v-col>
                      <v-col cols="12" md="9" class="mt-2">
                        <v-card
                          rounded="0"
                          flat
                          class="bg_color1 d-flex justify-center rounded-tr-lg"
                          dark
                        >
                          <v-card-title>Urutkan Timeline</v-card-title>
                        </v-card>
                        <div class="d-flex align-center justify-center">
                          <div
                            id="scroll-target"
                            class="pa-3 d-flex align-start mb-6 overflow-y-auto"
                            style="max-width: 100%"
                          >
                            <div
                              v-scroll:#scroll-target="onScroll"
                              v-for="(item, n) in true_subTimeline"
                              class="d-flex align-center justify-center flex-column"
                              :key="n"
                            >
                              <div style="width: 100px; height: 100%">
                                <div class="d-flex align-center justify-center">
                                  <v-divider
                                    v-if="n > 0"
                                    style="
                                      background: black;
                                      z-index: 1;
                                      width: 100px;
                                    "
                                  ></v-divider>
                                  <v-spacer v-else></v-spacer>
                                  <v-card
                                    flat
                                    width="40px"
                                    height="40px"
                                    class="rounded-circle d-flex align-center justify-center bg_color1"
                                    dark
                                  >
                                    {{ n + 1 }}
                                  </v-card>
                                  <v-divider
                                    v-if="n < true_subTimeline.length - 1"
                                    style="
                                      background: black;
                                      z-index: 1;
                                      width: 100px;
                                    "
                                  ></v-divider>
                                  <v-spacer v-else></v-spacer>
                                </div>
                                <h4 class="text-center" style="color: black">
                                  {{ item.step }}
                                </h4>
                              </div>
                            </div>
                          </div>
                        </div>
                        <draggable
                          v-model="true_subTimeline"
                          class="list-group"
                          @start.prevent="drag = true"
                          @end="draged"
                          v-bind="dragOptions"
                          handle=".handle"
                        >
                          <transition-group
                            type="transition"
                            :name="!drag ? 'flip-list' : null"
                          >
                            <div
                              class="ml-2"
                              v-for="(item, n) in true_subTimeline"
                              :key="`key-${n}`"
                            >
                              <v-card
                                flat
                                rounded="0"
                                :class="
                                  n >= true_subTimeline.length - 1
                                    ? 'pa-2 d-flex align-center justify-center flex-column rounded-br-lg'
                                    : 'mb-1 pa-2 d-flex align-center justify-center flex-column'
                                "
                                color="#E9ECF1"
                              >
                                <div style="width: 100%">
                                  <div class="d-flex px-2 align-center">
                                    <v-btn
                                      icon
                                      class="handle text-capitalize mr-2"
                                      style="cursor: move"
                                    >
                                      <v-icon> mdi-drag</v-icon>
                                    </v-btn>
                                    <h4 style="flex-grow: 1">
                                      {{ item.step }}
                                    </h4>
                                    <v-btn
                                      icon
                                      @click="removeTrueSubTimeline(n)"
                                      class="text-capitalize"
                                      dark
                                      color="red"
                                    >
                                      <v-icon> mdi-delete</v-icon>
                                    </v-btn>
                                  </div>
                                </div>
                              </v-card>
                            </div>
                          </transition-group>
                        </draggable>
                      </v-col>
                    </v-row>
                  </div>
                  <div class="mt-6 d-flex align-center justify-end">
                    <v-menu transition="slide-y-transition" bottom offset-y>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          class="text-capitalize mr-2"
                          min-width="150px"
                          depressed
                          outlined
                          color="red"
                          v-bind="attrs"
                          v-on="on"
                        >
                          <h4 style="color: black">Reset</h4>
                        </v-btn>
                      </template>
                      <v-list>
                        <v-list-item>
                          <v-btn
                            block
                            class="text-capitalize"
                            depressed
                            @click="resetType('all')"
                          >
                            <h4 style="color: black">Reset All</h4>
                          </v-btn>
                        </v-list-item>
                        <v-list-item>
                          <v-btn
                            block
                            class="text-capitalize"
                            depressed
                            @click="resetType('page')"
                          >
                            <h4 style="color: black">Reset</h4>
                          </v-btn>
                        </v-list-item>
                      </v-list>
                    </v-menu>

                    <v-btn
                      :disabled="
                        !loadingButton && !loading ?
                          tab == 2
                            ? field2.jenis_layanan && true_subTimeline.length != 0
                              ? false
                              : true
                            : false
                          : false
                      "
                      width="15%"
                      class="text-capitalize"
                      depressed
                      color="#052633"
                      @click="nextStep"
                    >
                      <h4 v-if="!loadingButton" class="txt_white">Lanjut</h4>
                      <h4 v-if="loadingButton" class="txt_white">Loading...</h4>
                    </v-btn>
                  </div>
                </div>
                <div v-if="loading">
                  <v-skeleton-loader
                    v-for="(item, n) in 3"
                    :key="n"
                    type="article"
                  ></v-skeleton-loader>
                </div>
              </v-card>
            </v-stepper-content>
            <v-stepper-content :step="3">
              <detail-timeline
                v-if="preview && !loading"
                @nextStep="forceNext"
                ref="detailtimeline"
                :timeline="true_subTimeline"
                @next="nextStep"
                :field1="field1"
                :field2="field2"
                :linkform="link_pendaftaran"
                @reset="resetAll()"
              />
              <v-skeleton-loader
                v-if="loading"
                type="card-avatar, article, actions"
              ></v-skeleton-loader>
            </v-stepper-content>
            <v-stepper-content :step="4">
              <v-card
                v-if="!loading && preview"
                flat
                outlined
                class="full_w"
                rounded="lg"
                style="overflow: hidden"
              >
                <div class="text-center bg_color1 py-2">
                  <h4 class="txt_white">Info Event</h4>
                </div>

                <v-row no-gutters>
                  <v-col cols="12" md="4">
                    <v-card
                      flat
                      style="margin-top: 1px"
                      rounded="0"
                      class="pa-3"
                      color="#CDD0D1"
                    >
                      Nama Klien
                    </v-card>
                  </v-col>
                  <v-col cols="12" md="8">
                    <v-card
                      flat
                      style="margin-top: 1px"
                      rounded="0"
                      class="pa-3"
                    >
                      {{ field1.nama_client ? field1.nama_client : "-" }}
                    </v-card>
                  </v-col>
                  <v-col cols="12" md="4">
                    <v-card
                      flat
                      style="margin-top: 1px"
                      rounded="0"
                      class="pa-3"
                      color="#CDD0D1"
                    >
                      Tujuan Event
                    </v-card>
                  </v-col>
                  <v-col cols="12" md="8">
                    <v-card
                      flat
                      style="margin-top: 1px"
                      rounded="0"
                      class="pa-3"
                    >
                      {{ preview.tujuan_event }}
                    </v-card>
                  </v-col>
                  <v-col cols="12" md="4">
                    <v-card
                      flat
                      style="margin-top: 1px; margin-bottom: 1px"
                      rounded="0"
                      class="pa-3"
                      color="#CDD0D1"
                    >
                      Tipe Klien
                    </v-card>
                  </v-col>
                  <v-col cols="12" md="8">
                    <v-card
                      flat
                      style="margin-top: 1px"
                      rounded="0"
                      class="pa-3 text-capitalize"
                    >
                      {{ preview.tipe }}
                    </v-card>
                  </v-col>
                </v-row>

                <div class="text-center bg_color1 py-2">
                  <h4 class="txt_white">Timeline</h4>
                </div>
                <v-stepper elevation="0" alt-labels>
                  <div
                    id="scroll-target"
                    class="overflow-y-auto"
                    style="max-width: 100%"
                  >
                    <v-stepper-header
                      class="pa-3"
                      v-scroll:#scroll-target="onScroll"
                    >
                      <div class="d-flex align-center justify-center full_w">
                        <template v-for="(item, n) in preview.timelines">
                          <v-stepper-step
                            color="#052633"
                            :key="n"
                            :step="n + 1"
                            complete
                            :complete-icon="`mdi-numeric-${n + 1}`"
                          >
                            <p class="text-center medium_txt ma-0">
                              {{ item.step }}
                            </p>
                          </v-stepper-step>
                          <v-divider
                            class="bg_color1"
                            :key="`divider${n}`"
                            v-if="n < preview.timelines.length - 1"
                          />
                        </template>
                      </div>
                    </v-stepper-header>
                  </div>
                </v-stepper>

                <div class="text-center bg_color1 py-2">
                  <h4 class="txt_white">Detail Jadwal</h4>
                </div>
                <v-row
                  no-gutters
                  v-for="(item, n) in preview.timelines"
                  :key="n"
                >
                  <v-col cols="12" md="4">
                    <v-card
                      flat
                      style="margin-top: 1px"
                      rounded="0"
                      class="pa-3"
                      color="#CDD0D1"
                    >
                      Jadwal {{ item.step }}
                    </v-card>
                  </v-col>
                  <v-col cols="12" md="8">
                    <v-card
                      flat
                      style="margin-top: 1px"
                      rounded="0"
                      class="pa-3"
                    >
                      Tanggal
                      {{ $date(item.start_date).format("YYYY-MM-DD") }}
                      sampai
                      {{ $date(item.end_date).format("YYYY-MM-DD") }}, Pukul
                      {{ item.start_time }} s/d {{ item.end_time }}
                    </v-card>
                  </v-col>
                </v-row>
              </v-card>
              <v-skeleton-loader
                class="pa-2 elevation-0"
                type="table-tbody"
                v-if="loading"
              ></v-skeleton-loader>
              <div class="mt-6 d-flex align-center justify-end">
                <v-btn
                  :disabled="!loadingButton && !loading ? false: true"
                  class="text-capitalize mr-2"
                  width="15%"
                  depressed
                  outlined
                  color="red"
                  @click="resetType('all')"
                >
                  <h4 style="color: black">Reset All</h4>
                </v-btn>
                <v-btn
                  :disabled="!loadingButton && !loading ? false: true"
                  width="15%"
                  class="text-capitalize"
                  depressed
                  color="#052633"
                  @click="nextStep"
                >
                  <h4 v-if="!loadingButton" class="txt_white">Lanjut</h4>
                  <h4 v-if="loadingButton" class="txt_white">Loading...</h4>
                </v-btn>
              </div>
            </v-stepper-content>
          </v-stepper-items>
        </v-stepper>
      </div>
    </div>
    <dialog-small
      :d_small="d_small"
      @refetch="type_reset == 'all' ? resetAll() : resetFunction()"
      @close="d_small = false"
      :inventory="inventory"
      :type="d_type"
      :title="d_title"
    />
  </div>
</template>

<script>
import { mapState } from "vuex";
import draggable from "vuedraggable";
import bullet from "../../components/Etc/bullet.vue";
import DetailTimeline from "./detailTimeline.vue";
import NavbarProfile from "../../components/navbarProfile/navbarProfile.vue";
export default {
  components: { bullet, draggable, DetailTimeline, NavbarProfile },
  nama: "createEvent",
  computed: {
    dragOptions() {
      return {
        animation: 200,
        group: "description",
        disabled: false,
        ghostClass: "ghost",
      };
    },
    ...mapState({}),
  },
  data() {
    return {
      type_reset: "all",
      d_small: false,
      d_type: "",
      d_title: "",
      inventory: "",
      line: "",
      offsetTop: 0,
      loading: true,
      loadingButton: false,
      tab: 1,
      dataClient: null,
      dataLayanan: [],
      dataSet: null,
      templateSubTimeline: null,
      subTimeline: [],
      true_subTimeline: [],
      custom_subtimeline: "",
      preview: null,
      link_pendaftaran: "",
      refresh: true,
      field1: {
        nama_client: "",
        id_klien: "",
        tujuan_event: "",
        tipe_klien: "",
      },
      field2: {
        jenis_layanan: "",
      },
      items: [
        {
          text: "Home",
          disabled: false,
          href: "/",
        },
        {
          text: "List Event",
          disabled: false,
          href: "/admin/mo/event",
        },
        {
          text: "Tambah Event",
          disabled: true,
          href: "#",
        },
      ],
      drag: false,
    };
  },
  mounted() {
    this.fetchData(true, "");
    this.subTimeline = [];
  },
  methods: {
    checkNamaClient(id_klien) {
      for (let i = 0; i < this.dataClient.length; i++) {
        if (this.dataClient[i].id == id_klien) {
          this.field1.nama_client = this.dataClient[i].nama_lengkap;
        }
      }
    },
    fetchData(first, step) {
      this.loading = true;
      this.loadingButton = true;
      let isCreating = localStorage.getItem("creating");
      if (!isCreating) {
        this.$router.push("/admin/mo/event");
      }
      let data = {
        path: `sysadmin/event/wizard`,
      };
      this.$store
        .dispatch("fileData/getData", data)
        .then(async (data) => {
          if (first) {
            if (data.data) {
              this.fetchDataSubTimeline(
                true,
                data.data.layanan_id,
                false,
                "clear",
                step
              );
            } else {
              this.fetchDataSubTimeline(true, 1, false, "clear", step);
            }
            this.loadingButton = false;
          } else {
            if (data.data) {
              // this.fetchDataSubTimeline(
              //   false,
              //   data.data.layanan_id,
              //   true,
              //   "",
              //   step
              // );
            } else {
              this.fetchDataSubTimeline(false, 1, false, "", step);
            }
            this.loadingButton = false;
          }
          this.dataClient = data.list_customer;
          this.preview = data.data;
          this.link_pendaftaran = data.link_daftar;
        })
        .catch((e) => {
          this.$store.dispatch("notify", {
            msg: e.message,
            type: "error",
          });
          this.loading = false;
        });
    },
    async fetchDataSubTimeline(first, idx, not_generate, type, step) {
      this.loading = true;
      let data = {
        path: `sysadmin/event/daftar-step/${idx}`,
      };
      this.$store
      .dispatch("client/getData", data)
      .then(async(data) => {
          this.templateSubTimeline = data;
          if (type == "clear") {
            this.true_subTimeline = [];
          }
          await this.refreshDataSubTimeline(first, false, not_generate, step);
        })
        .catch((e) => {
          this.$store.dispatch("notify", {
            msg: e.message,
            type: "error",
          });
          this.loading = false;
        });
    },
    refreshDataSubTimeline(first, not_generate, item, step) {
      this.refresh = true;
      let dataTemp = [];
      this.templateSubTimeline.forEach((item) => {
        let is_same = false;
        let done = false;
        if (this.true_subTimeline.length != 0) {
          for (let i = 0; i < this.true_subTimeline.length; i++) {
            if (item.id == this.true_subTimeline[i].id) {
              is_same = true;
              done = true;
              break;
            }
            done = true;
          }
          if (!is_same & done) {
            dataTemp.push(item);
          }
        } else {
          dataTemp.push(item);
          is_same = true;
          done = true;
        }
      });
      if(this.field1.id_klien != ''){
        this.subTimeline = dataTemp;
      }
      this.refresh = false;
      if (this.tab == 3) {
        let interval = setInterval(() => {
          if (this.$refs.detailtimeline) {
            this.$refs.detailtimeline.setField3();
            this.$refs.detailtimeline.setVariabel();
            clearInterval(interval);
          }
        }, 300);
      }
      if (!not_generate) {
        this.generateData(first, step);
      } else {
        this.loading = false;
      }
    },

    generateData(first, step) {
      if (first) {
        if (this.preview) {
          if (step == "") {
            this.tab = this.preview.step;
          }
          this.checkNamaClient(this.preview.customer_id);
          this.field1.id_klien = this.preview.customer_id;
          this.field1.tujuan_event = this.preview.tujuan_event;
          this.field1.tipe_klien = this.preview.tipe;
          this.field2.jenis_layanan = this.preview.layanan_id;
          if (this.preview.timelines) {
            for (let i = 0; i < this.preview.timelines.length; i++) {
              for (let j = 0; j < this.subTimeline.length; j++) {
                if (this.preview.timelines[i].id == this.subTimeline[j].id) {
                  this.true_subTimeline.push({
                    step: this.subTimeline[j].step,
                    step_id: this.subTimeline[j].id,
                    id: this.subTimeline[j].id,
                    deskripsi: this.preview.timelines[i].deskripsi
                      ? this.preview.timelines[i].deskripsi
                      : "",
                    start_date: this.preview.timelines[i].start_date
                      ? this.preview.timelines[i].start_date
                      : "",
                    start_time: this.preview.timelines[i].start_time
                      ? this.preview.timelines[i].start_time
                      : "",
                    end_date: this.preview.timelines[i].end_date
                      ? this.preview.timelines[i].end_date
                      : "",
                    end_time: this.preview.timelines[i].end_time
                      ? this.preview.timelines[i].end_time
                      : "",
                    metode: this.preview.timelines[i].metode
                      ? this.preview.timelines[i].metode
                      : "",
                    lokasi: this.preview.timelines[i].lokasi
                      ? this.preview.timelines[i].lokasi
                      : "",
                    file: this.preview.timelines[i].file
                      ? this.preview.timelines[i].file
                      : null,
                    file_path: this.preview.timelines[i].file_path
                      ? this.preview.timelines[i].file_path
                      : "",
                    order: this.preview.timelines[i].order
                      ? this.preview.timelines[i].order
                      : i + 1,
                  });
                }
              }
            }
          }
          this.refreshDataSubTimeline(false, false);
          this.loadingButton = false;
        } else {
          this.fetchDataLayanan();
        }
      }else {
        this.fetchDataLayanan();
      }
    },
    fetchDataLayanan() {
      this.loading = true;
      let data = {
        path: `sysadmin/event/daftar-layanan`,
      };
      this.$store
        .dispatch("client/getData", data)
        .then((data) => {
          this.dataLayanan = data;
          this.loading = false;
        })
        .catch((e) => {
          this.$store.dispatch("notify", {
            msg: e.message,
            type: "error",
          });
          this.loading = false;
        });
    },
    async prevStep(tab) {
      if (tab == 3) {
        await this.$refs.detailtimeline.setField3();
        this.$refs.detailtimeline.setVariabel();
        this.$refs.detailtimeline.setDetail();
      }
      if (this.tab > 0 && tab != 3) {
        this.saveData("prev", tab);
      }
    },
    async nextStep() {
      if(this.field1.id_klien == ''){
        this.subTimeline = [];
      }
      if (this.tab == 2) {
        await this.$refs.detailtimeline.setField3();
        this.$refs.detailtimeline.setVariabel();
      }
      if (this.tab < 4) {
        this.saveData("next", "");
      }
      if (this.tab == 4) {
        this.saveEvent(this.preview.id);
      }
    },
    async forceNext() {
      this.fetchData(true, "sk");
      this.tab = this.tab + 1;
    },
    async saveData(type, tab) {
      this.loadingButton = true;
      this.refresh = false;
      let tempTimeline = [];
      switch (this.tab) {
        case 1:
          tempTimeline.push(this.field1);
          break;
        case 2:
          tempTimeline.push(this.field2);
          break;
        case 4:
          tempTimeline.push(this.field4);
          break;

        default:
          break;
      }
      let dataEvent = [
        {
          step: this.tab,
        },
        {
          customer_id: this.field1.id_klien,
        },
        {
          tujuan_event: this.field1.tujuan_event,
        },
        {
          tipe: this.field1.tipe_klien,
        },
        {
          layanan_id: this.field2.jenis_layanan,
        },
        {
          timelines: JSON.stringify(tempTimeline),
        },
      ];

      let path = `sysadmin/event/wizard/save`;
      let data = {
        body: dataEvent,
        path: path,
      };
      await this.$store
        .dispatch("event/postApi", data)
        .then((data) => {
          this.fetchData(false, "");
          this.loadingButton = false;
          if (data.code == 200) {
            if (type == "next") {
              this.tab = this.tab + 1;
            }
          } else if (data.code == 201) {
            if (data.data) {
              var key = Object.keys(data.data.errors);
              var values = Object.values(data.data.errors);
              for (let i = 0; i < key.length; i++) {
                for (let j = 0; j < values[i].length; j++) {
                  this.$toast.error(values[i][j]);
                }
              }
            } else {
              this.$store.dispatch("notify", {
                msg: data.message,
                type: "error",
              });
            }
          }
          this.loading = false;
        })
        .catch((e) => {
          this.loadingButton = false;
          var key = Object.keys(e.data.errors);
          var values = Object.values(e.data.errors);
          if (key.length == 0) {
            this.$store.dispatch("notify", {
              msg: e.message,
              type: "error",
            });
          }
          for (let i = 0; i < key.length; i++) {
            for (let j = 0; j < values[i].length; j++) {
              this.$toast.error(values[i][j]);
            }
          }
          this.loading = false;
        });
    },
    saveEvent(id) {
      this.loadingButton = true;
      let data = {
        body: {
          id: id,
        },
        path: `sysadmin/event/save`,
      };
      this.$store
        .dispatch("client/postApi", data)
        .then((data) => {
          this.loadingButton = false;
          this.$store.dispatch("notify", {
            msg: data.message,
            type: "success",
          });
          // this.$router.push(`/admin/mo/invoice-event/create/${data.data.id}`);
          this.$router.push(`/admin/mo/event`);
          localStorage.removeItem("creating");
        })
        .catch((e) => {
          this.loadingButton = false;
          this.$store.dispatch("notify", {
            msg: e.message,
            type: "error",
          });
        });
    },
    addSubTimeline(idx) {
      this.refresh = true;
      if (idx != -2) {
        this.true_subTimeline.push({
          step: this.subTimeline[idx].step,
          step_id: this.subTimeline[idx].id,
          id: this.subTimeline[idx].id,
          deskripsi: "",
          start_date: "",
          start_time: "",
          end_date: "",
          end_time: "",
          metode: "",
          lokasi: "",
          file: null,
          file_path: "",
          order: this.true_subTimeline.length,
        });
        // this.true_subTimeline.push(this.subTimeline[idx]);
      } else {
        if (!this.custom_subtimeline && !this.field1.id_klien) {
          this.$toast.error(`Klien tidak boleh kosong!`);
          this.$toast.error(`Custom timeline tidak boleh kosong!`);
        } else if (!this.custom_subtimeline) {
          this.$toast.error(`Custom timeline tidak boleh kosong!`);
        } else if (!this.field1.id_klien) {
          this.$toast.error(`Klien tidak boleh kosong!`);
        } else {
          this.createCustom();
        }
      }
      this.refreshDataSubTimeline(false, true);
    },
    async removeTrueSubTimeline(idx) {
      this.true_subTimeline.splice(idx, 1);
      this.refreshDataSubTimeline(false, true);
    },
    createCustom() {
      let data = {
        body: {
          event_layanan_id: this.field2.jenis_layanan,
          step: this.custom_subtimeline,
        },
        path: `sysadmin/event/daftar-step/custom`,
      };
      this.$store
        .dispatch("client/postApi", data)
        .then((data) => {
          this.custom_subtimeline = "";
          this.fetchDataSubTimeline(
            false,
            this.field2.jenis_layanan,
            false,
            ""
          );
        })
        .catch((e) => {
          this.$store.dispatch("notify", {
            msg: e.message,
            type: "error",
          });
        });
    },

    onScroll(e) {
      this.offsetTop = e.target.scrollTop;
    },
    draged() {
      this.drag = false;
    },
    resetType(item) {
      this.type_reset = item;
      this.inventory = {
        type: item,
      };
      this.d_type = "d_reset";
      this.d_title = "Reset Data";
      this.d_small = true;
      this.line = true;
    },
    resetFunction() {
      switch (this.tab) {
        case 1:
          for (let i in this.field1) {
            this.field1[i] = "";
          }
          break;

        case 2:
          for (let i in this.field2) {
            this.field2[i] = "";
          }
          this.true_subTimeline = [];
          this.refreshDataSubTimeline(false, true);
          break;

        default:
          break;
      }
    },
    resetAll() {
      this.tab = 1;
      for (let i in this.field1) {
        this.field1[i] = "";
      }
      for (let i in this.field2) {
        this.field2[i] = "";
      }
      this.true_subTimeline = [];
      this.refreshDataSubTimeline(false, true);
    },
  },
};
</script>

<style scoped>
.container {
  background-color: #08c708;
}
</style>
